import React from "react";
import {
  Box,
  Text,
  HStack,
  Button,
  VStack,
  Heading,
  Img,
  Flex,
  Image,
} from "@chakra-ui/react";
import img1 from "./russia1.jpg";
import img2 from "./russia2.jpg";
import { BsCheckCircle } from "react-icons/bs";
import Internation from "../../pages/Internation";
import bgImage from "./russia_banner.jpg"
const Russia = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`} // Update background image
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
                   "MBBS In Russia"

        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={4}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In Russia
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              Russia is the largest country in the world, spanning Eastern
              Europe and northern Asia. It is bordered by numerous countries
              including Norway, Finland, Estonia, Latvia, Belarus, Ukraine,
              Georgia, and Azerbaijan. To the east, it extends across the vast
              expanse of Siberia and to the west, it has a coastline along the
              Baltic Sea, while the southern part is bounded by Kazakhstan,
              China, and Mongolia. Moscow, the capital city, is situated in the
              western part of the country.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              Approximately 145 million (2024).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              17,098,242 km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Moscow
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              Russia's climate varies greatly across its vast territory. The
              western part of Russia experiences a temperate climate with warm
              summers and cold winters, while Siberia and the eastern regions
              endure an extreme continental climate with very cold winters and
              hot summers. The northern regions, including parts of Siberia,
              have an Arctic climate with long, harsh winters.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              The dominant religion in Russia is Russian Orthodoxy, followed by
              around 70% of the population. Islam is practiced by about 15% of
              the population, with other Christian denominations and
              non-religious individuals constituting the remaining 15%.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              99.7% (Male - 99.7% and Female - 99.7%).
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4}>
              <Img src={img1}  height={{base:"30vh",lg:"35vh"}} width="100%" borderRadius="md" boxShadow="md" />
              <Img src={img2} height={{base:"30vh",lg:"35vh"}} width="100%" borderRadius="md" boxShadow="md" />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why Russia?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Low Tuition Fee</li>
            <li>Full English Medium Programs</li>
            <li>Five to Six Years MBBS Programme</li>
            <li>WHO, MCI Approved</li>
            <li>Internationally Recognized Degrees</li>
            <li>Modern Infrastructure and Facilities</li>
            <li>Rich Cultural and Historical Heritage</li>
            <li>No Entrance Exam for International Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          Russia MBBS Colleges
        </Heading>
        <Internation state="Russia" />
      </Box>
    </Box>
  );
};

export default Russia;
