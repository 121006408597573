import React from "react";
import {
  Box,
  Text,
  HStack,
  Button,
  VStack,
  Heading,
  Img,
  Flex,
  Image,
} from "@chakra-ui/react";
import img1 from "./krygyzstan1.jpg";
import img2 from "./kyrgystan2.jpg";
import { BsCheckCircle } from "react-icons/bs";
import Internation from "../../pages/Internation";
import bgImage from "./kyrgyzstan_banner.jpg";
const Kyrgystan = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`} // Update background image
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
          "MBBS In Kyrgyzstan"
        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={4}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In Kyrgyzstan
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              Kyrgyzstan is a landlocked country in Central Asia. It is bordered
              by Kazakhstan to the north, Uzbekistan to the west, Tajikistan to
              the south, and China to the east. The country is characterized by
              its mountainous terrain, including the Tien Shan and Pamir
              mountain ranges. The capital city is Bishkek, situated in the
              northern part of the country.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              Approximately 6.7 million (2024).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              199,951 km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Bishkek
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              Kyrgyzstan has a continental climate with cold winters and warm
              summers. The mountainous areas experience heavy snowfall in winter
              and cooler temperatures in summer. The climate is generally dry
              with low annual precipitation.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              Islam is the predominant religion in Kyrgyzstan, with around 90%
              of the population practicing Islam, predominantly Sunni. The
              remaining 10% includes Russian Orthodox Christians, as well as
              other minority religions.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              99.8% (Male - 99.8% and Female - 99.8%).
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4}>
              <Img
                src={img1}
                height={{ base: "30vh", lg: "30vh" }}
                width="100%"
                borderRadius="md"
                boxShadow="md"
              />
              <Img src={img2} width="100%" borderRadius="md" boxShadow="md" />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why Kyrgyzstan?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Affordable Tuition Fees</li>
            <li>Programs Offered in English</li>
            <li>Five to Six Years MBBS Program</li>
            <li>Accredited by WHO and Several Medical Councils</li>
            <li>Cost-effective Living Expenses</li>
            <li>Rich Cultural Experience</li>
            <li>Safe and Peaceful Living Environment</li>
            <li>No Entrance Exam for International Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          Kyrgyzstan MBBS Colleges
        </Heading>
        <Internation state="Kyrgyzstan" />
      </Box>
    </Box>
  );
};

export default Kyrgystan;
