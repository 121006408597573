import React from "react";
import {
  Box,
  Text,
  HStack,
  Img,
  Heading,
  VStack,
} from "@chakra-ui/react";
import img1 from "./china1.jpg";  // Update with new image
import img2 from "./china2.jpg";  // Update with new image
import Internation from "../../pages/Internation";
import bgImage from "./china_banner.png";  // Update with new background image

const China = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`}  // Updated background image URL
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
          "MBBS In China"
        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={6}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In China
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              China is a country in East Asia, bordered by 14 countries including Russia, Mongolia, India, Nepal, and Vietnam. It is the world's most populous country, with a diverse landscape that includes mountains, plateaus, deserts, and rivers. The capital city is Beijing, and the country covers an area of approximately 9.6 million square kilometers.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              Approximately 1.4 billion (2024).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              9.6 million km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Beijing
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              China has a diverse climate that ranges from tropical in the south to subarctic in the north. The eastern regions experience a temperate climate with four distinct seasons, while the western regions are more arid and have extreme temperature variations.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              China is officially atheist, but major religions practiced include Buddhism, Taoism, Islam, and Christianity. Buddhism and Taoism are the most widespread, with Buddhism being practiced by around 18% of the population and Taoism by approximately 12%.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              97.7% (Male - 98.3% and Female - 97.1%).
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4}>
              <Img src={img1} height={{base:"50vh",lg:"30vh"}} width="100%" borderRadius="md" boxShadow="md" />  {/* Updated image URLs */}
              <Img src={img2} height={{base:"50vh",lg:"30vh"}} width="100%" borderRadius="md" boxShadow="md" />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why China?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Affordable Tuition Fees</li>
            <li>English Medium Instruction</li>
            <li>Five-year MBBS Programme</li>
            <li>Accredited by WHO and Various Medical Councils</li>
            <li>High-Quality Education with Modern Facilities</li>
            <li>
              Access to International Medical Textbooks and Resources
            </li>
            <li>
              Language is not a barrier as the medium of instruction is English.
            </li>
            <li>No Entrance Exam for International Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          China MBBS Colleges
        </Heading>
        <Internation state="China" /> 
      </Box>
    </Box>
  );
};

export default China;
