import React from "react";
import {
  Box,
  Text,
  HStack,
  Button,
  VStack,
  Heading,
  Img,
  Flex,
  Image,
} from "@chakra-ui/react";
import img1 from "./bangladesh1.jpg";
import img2 from "./bangladesh2.jpg";
import Internation from "../../pages/Internation";
import bgImage from "./bangladesh_banner.jpeg";
const Bangladesh = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`} // Updated background image URL
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
          "MBBS In Bangladesh"
        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={6}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In Bangladesh
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              Bangladesh is a country in South Asia, located on the Bay of
              Bengal. It is bordered by India on the west, north, and east, and
              Myanmar (Burma) on the southeast. To the south, it faces the Bay
              of Bengal. The capital and largest city is Dhaka. Bangladesh
              covers a territory of 147,570 square kilometers, and its
              population is over 160 million, making it one of the most densely
              populated countries in the world.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              The population of Bangladesh is now approximately 170 million.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              147,570 km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Dhaka
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              Bangladesh has a tropical monsoon climate characterized by heavy
              seasonal rainfall, high temperatures, and high humidity. There are
              three distinct seasons: a hot, humid summer from March to June; a
              cool, rainy monsoon season from June to October; and a cool, dry
              winter from October to March.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              Islam is practiced by approximately 90.4% of the population, while
              Hinduism is followed by 8.5%. Other religions constitute around
              1.1% of the population.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              75.3% (Male - 78.0% and Female - 72.5%)
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4}>
              <Img
                src={img1}
                width="100%"
                height={{ base: "50vh", lg: "30vh" }}
                borderRadius="md"
                boxShadow="md"
              />{" "}
              {/* Updated image URLs */}
              <Img
                src={img2}
                width="100%"
                height={{ base: "50vh", lg: "30vh" }}
                borderRadius="md"
                boxShadow="md"
              />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why Bangladesh?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Affordable Tuition Fees</li> {/* Updated list items */}
            <li>English Medium Instruction</li>
            <li>5-Year MBBS Program</li>
            <li>Approved by WHO, MCI</li>
            <li>High-Quality Education and Facilities</li>
            <li>Access to Latest Medical Textbooks and Resources</li>
            <li>No Entrance Exams for Indian Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          Bangladesh MBBS Colleges
        </Heading>
        <Internation state="Bangladesh" />{" "}
        {/* Ensure this component or data is updated as needed */}
      </Box>
    </Box>
  );
};

export default Bangladesh;
