import React, { useEffect, useState } from "react";
import banner from "./main-hero2.png";
import img1 from "./college-icon2.png";
import img2 from "./college-icon3.png";
import img3 from "./istockphoto-532548555-170667a.jpg";
import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  background,
  position,
  Stack,
  Select,
  Grid,
  GridItem,
  Img,
  Input,
  FormControl,
  Textarea,
  Image,
  CircularProgress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import {
  HStack,
  Flex,
  Icon,
  Divider,
  Button,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import img4 from "./russia.png";
import img5 from "./Bangladesh.jpg";
import img6 from "./Nepal.webp";
import img7 from "./Kazakhstan.png";
import img8 from "./flag-round-250.png";
import img9 from "./about-thumbnail.jpg";
import img10 from "./service-icon1.png";
import img11 from "./service-icon2.png";
import img12 from "./service-icon3.png";
import img13 from "./service-icon4.png";

import img14 from "./service-icon5.png";
import img15 from "./service-icon6.png";
import img16 from "./blog-1.jpg";
import img17 from "./istockphoto-1447889800-170667a.webp";
import img18 from "./istockphoto-532548555-170667a.jpg";
import img19 from "./call-us.jpg";
import img20 from "./medical in india.jpg";
import img21 from "./medical in abroad.jpg";
import img39 from "./blog1.png";
import img40 from "./blog2.png";
import img41 from "./blog3.png";
import img42 from "./blog4.png";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import { AuthContext } from "./AuthContext/AuthContextProvider";
import axios from "axios";
import Contact from "./Contact";
import Runningnumber from "./Running";
const Home = () => {
  const [topRecommendedCollege, setTopRecommendedCollege] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    educationInterest: "",
    examScore: "",
    budget: "",
  });
  const isAuth = localStorage.getItem("isAuth");
  const [formData, setFormData] = useState();

  const navigate = useNavigate();
  const toast = useToast();
  const handlesubmit = () => {
    const course = document.querySelector(".dropdown1");
    const val = course.value;
    console.log(val);
    navigate(`/courses/${val}`);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleGet = async () => {
    try {
      // const res = await fetch("http://localhost:3008/api/data");
      const res = await fetch(
        "https://api.admissionpossibility.com/api/get-all-blog"
      );
      // const res = await fetch("https://api.admissionpossibility.com/api/get-all-blog");

      const data = await res.json();
      const limitedData =
        data.blogs.length > 4 ? data.blogs.slice(0, 4) : data.blogs;
      setFormData(limitedData);
      // console.log(data.blogs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handlecontactclick = () => {
    navigate(`/contact`);
  };

  const getUniversity = async () => {
    try {
      // const res = await axios.get(`http://localhost:5000/api/universities`);
      const res = await axios.get(
        `https://api.admissionpossibility.com/api/universities`
      );
      // const res = await axios.get(`https://api.admissionpossibility.com/api/all/universities`);

      const data = res.data.universities;

      const filteredColleges = data.filter(
        (college) =>
          college.country !== "Russia" &&
          college.country !== "Nepal" &&
          college.country !== "Kyrgyzstan" &&
          college.country !== "Bangladesh" &&
          college.country !== "China" &&
          college.country !== "Kazakhstan" &&
          college.country !== "Georgia"
      );

      const shuffledColleges = filteredColleges.sort(() => 0.5 - Math.random());

      const recommendedColleges = shuffledColleges.slice(0, 4);

      setTopRecommendedCollege(recommendedColleges);
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    handleGet();
    if (topRecommendedCollege?.length === 0) {
      getUniversity();
    }
  }, []);

  const validateEmail = (email) => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    const isValidFormat = emailPattern.test(email);
    const isValidDomain = email.includes(".com") || email.includes(".in");

    return isValidFormat && isValidDomain;
  };

  const handleConselling = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        // "http://localhost:5000/api/add-conselling",
        "https://api.admissionpossibility.com/api/add-conselling",
        forms
      );

      if (res.status === 200) {
        toast({
          title: "Counselling Form submit Succefully",
          description: "Submit Successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        setForms({
          name: "",
          email: "",
          phone: "",
          educationInterest: "",
          examScore: "",
          budget: "",
        });
        handleClose();
      } else {
        toast({
          title: "Something went wrong",
          description: "Please try again later.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast({
        title: "Something went wrong",
        description: "Please try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleConsel = (e) => {
    const { name, value } = e.target;
    setForms((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div style={{ marginTop: "16vh" }}>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent py={12} px={8}>
          <ModalHeader textAlign="center" fontSize="1.2rem">
            Fill This Form & Get Your Free Counseling Now
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleConselling}>
              <FormControl mb={4} isRequired>
                <Input
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  onChange={handleConsel}
                  value={forms.name}
                />
              </FormControl>

              <FormControl
                mb={4}
                isRequired
                isInvalid={forms.phone && forms.phone.length !== 10}>
                <Input
                  type="tel"
                  placeholder="Phone"
                  name="phone"
                  onChange={handleConsel}
                  value={forms.phone}
                  pattern="[0-9]{10}"
                />
                <FormErrorMessage>
                  Please enter a valid 10-digit phone number.
                </FormErrorMessage>
              </FormControl>

              <FormControl
                mb={4}
                isRequired
                isInvalid={forms.email && !validateEmail(forms.email)}>
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleConsel}
                  value={forms.email}
                />
                <FormErrorMessage>
                  Email address is not valid or does not include .com or .in
                </FormErrorMessage>
              </FormControl>

              <FormControl mb={4} isRequired>
                <Input
                  type="text"
                  placeholder="Which Course are you interested in?"
                  name="educationInterest"
                  onChange={handleConsel}
                  value={forms.educationInterest}
                />
              </FormControl>

              <FormControl mb={4} isRequired>
                <Input
                  type="text"
                  placeholder="Entrance Exam Score"
                  name="examScore"
                  onChange={handleConsel}
                  value={forms.examScore}
                />
              </FormControl>

              <FormControl mb={4} isRequired>
                <Input
                  type="text"
                  placeholder="Budget"
                  name="budget"
                  onChange={handleConsel}
                  value={forms.budget}
                />
              </FormControl>

              <Flex alignItems="center" justifyContent="space-between" mt={6}>
                <Button
                  as="a"
                  href={"tel:+91-98739 85980"}
                  background="#01B4EC"
                  color="white"
                  _hover={{ background: "#008CC9" }}
                  width="48%">
                  Call Now
                </Button>

                <Button type="submit" colorScheme="blue" width="48%">
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <div className="banner" style={{ height: "70vh", marginTop: "4vh" }}>
        <Box
          fontSize={["3rem", "3rem", "3rem", "4rem", "4rem"]}
          color={"#01B4EC"}
          zIndex={"1"}
          position={"absolute"}
          top={"10vh"}
          left={"10vw"}
          marginBottom={"18"}
          width={["80%", "80%", "80%", "40%", "40%"]}>
          <Text textAlign={"start"}>Your Education</Text>

          <Text textAlign={"start"}>Your Future</Text>
          <Text
            textAlign={"start"}
            fontSize={"1rem"}
            width={"90%"}
            color={["black", "#01B4EC"]}>
            Explore a diverse array of colleges and universities across the
            globe, unlocking boundless opportunities for your academic journey.
          </Text>

          <Flex width={"80%"} marginTop={"6vh"}>
            {isAuth ? (
              <Button
                padding={"13px 18px"}
                fontSize={["0.7rem", "1.3rem", "0.7rem", "1.3rem", "1.3rem"]}
                background={"white"}
                color={"black"}
                fontFamily={"poppins"}
                fontWeight={"normal"}
                marginRight={"5vw"}
                width={["30vw", "14vw"]}
                height={"6vh"}
                paddingX={"15px"}
                onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button
                padding={"13px 18px"}
                fontSize={["0.7rem", "1.3rem", "0.7rem", "1.3rem", "1.3rem"]}
                background={"white"}
                color={"black"}
                fontFamily={"poppins"}
                fontWeight={"normal"}
                marginRight={"5vw"}
                width={["30vw", "14vw"]}
                height={"6vh"}
                paddingX={"15px"}
                onClick={() => {
                  navigate("/login/user");
                }}>
                Login
              </Button>
            )}

            <Button
              padding={"13px 18px"}
              fontSize={["0.7rem", "1.3rem", "0.7rem", "1.3rem", "1.3rem"]}
              background={"#01B4EC"}
              color={"black"}
              onClick={handlecontactclick}
              height={"6vh"}
              fontFamily={"poppins"}
              fontWeight={"normal"}
              width={["30vw", "14vw"]}>
              Contact now
            </Button>
          </Flex>
        </Box>
      </div>

      {/* <Box
        // style={{ width: "99vw" }}
        height={["80vh", "80vh", "80vh", "39vh", "39vh"]}
        marginTop={["11vh", "11vh", "11vh", "11vh", "11vh"]}
      > */}
      <Flex
        pt={10}
        width={"80%"}
        gap={8}
        margin={"auto"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={["column", "column", "column", "row", "row"]}>
        <Box
          width={["90vw", "90vw", "75vw", "35vw", "35vw"]}
          background={"white"}
          boxShadow="15px 4px 64px rgba(0, 0, 0, 0.15)"
          display={"flex"}
          justifyContent={"center"}
          borderBottom={"5px solid #6AB0D9"}
          alignItems={"center"}
          borderRadius={"20px"}
          position={"relative"}>
          <HStack width={"100%"} height={"100%"}>
            <Flex
              width={["90vw", "90vw", "90vw", "35vw", "35vw"]}
              height={"100%"}
              background={"white"}
              boxShadow="15px 4px 64px rgba(0, 0, 0, 0.15)"
              justifyContent={"center"}
              flexDirection={"column"}
              borderRadius={"20px"}>
              <Box
                py={2}
                width={["40vw", "40vw", "50vw", "15vw", "14vw"]}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                backgroundColor={"#6AB0D9"}
                color={"white"}
                borderRadius={"0px 0px 20px 20px"}
                fontWeight={"SemiBold"}>
                <Text
                  fontSize={{
                    base: "1rem",
                    md: "1rem",
                    lg: "1rem",
                    xl: "1.4rem",
                  }}>
                  Study In India
                </Text>
              </Box>
              <HStack
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                px={2}>
                <Box
                  width={{ base: "90%", md: "70%" }}
                  fontSize={["0.7rem", "0.7rem", "0.8rem", "0.9rem", "1rem"]}
                  textAlign={"start"}
                  mb={1}
                  mt={2}>
                  Embark on a Healing Odyssey: Study Medicine in India. Immerse
                  yourself in a vibrant and culturally diverse educational
                  experience. Explore prestigious medical institutions, embrace
                  clinical excellence, and shape your future as a compassionate
                  healer in the land of opportunity.
                </Box>

                <Box borderRadius={"lg"}>
                  <Image width={"100%"} borderRadius={"lg"} src={img20} />
                </Box>
              </HStack>
            </Flex>
          </HStack>
        </Box>

        <Box
          width={["90vw", "90vw", "75vw", "35vw", "35vw"]}
          background={"white"}
          boxShadow="15px 4px 64px rgba(0, 0, 0, 0.15)"
          display={"flex"}
          justifyContent={"center"}
          borderBottom={"5px solid #6AB0D9"}
          alignItems={"center"}
          borderRadius={"20px"}
          position={"relative"}>
          <HStack width={"100%"} height={"100%"}>
            <Flex
              width={["90vw", "90vw", "90vw", "35vw", "35vw"]}
              height={"100%"}
              background={"white"}
              boxShadow="15px 4px 64px rgba(0, 0, 0, 0.15)"
              justifyContent={"center"}
              flexDirection={"column"}
              borderRadius={"20px"}>
              <Box
                py={2}
                width={["40vw", "40vw", "50vw", "15vw", "14vw"]}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                backgroundColor={"#6AB0D9"}
                color={"white"}
                borderRadius={"0px 0px 20px 20px"}
                fontWeight={"SemiBold"}>
                <Text
                  fontSize={{
                    base: "1rem",
                    md: "1rem",
                    lg: "1rem",
                    xl: "1.4rem",
                  }}>
                  Study In Abroad
                </Text>
              </Box>
              <HStack
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                px={2}>
                <Box
                  width={{ base: "90%", md: "70%" }}
                  fontSize={["0.7rem", "0.7rem", "0.8rem", "0.9rem", "1rem"]}
                  textAlign={"start"}
                  mb={1}
                  mt={2}>
                  Embark on a Global Healing Journey: Study Medicine Abroad.
                  Immerse yourself in a world of diverse cultures and
                  exceptional medical education. Explore renowned international
                  medical institutions, embrace clinical excellence, and shape
                  your future as a globally-minded
                </Box>

                <Box borderRadius={"lg"}>
                  <Image width={"100%"} borderRadius={"lg"} src={img20} />
                </Box>
              </HStack>
            </Flex>
          </HStack>
        </Box>
      </Flex>
      {/* </Box> */}

      <Flex
        className="container2"
        // height={["100vh", "70vh", "60vh", "60vh"]}
        height={{ base: "90vh", md: "70vh" }}
        boxShadow={"lg"}
        flexDirection={"column"}
        py={10}
        mt={6}
        style={{
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Heading
          width={"94%"}
          style={{ marginTop: "3vh", fontWeight: 700, color: "#01B4EC" }}
          fontSize={{ base: "1rem", lg: "1.6rem" }}>
          STUDY ABROAD | TOP SCHOOLS, COLLAGES AND UNIVERSITIES IN ABROAD
        </Heading>
        <Text
          fontSize={{ base: "1rem", lg: "2rem" }}
          style={{ marginTop: "2vh", color: "#000000" }}>
          BETTER EDUCATION FOR A BETTER WORLD
        </Text>

        <Box
          width={"94vw"}
          backgroundColor={"#91D8F5"}
          marginTop={"10vh"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"18px"}>
          <Stack
            direction={["column", "row"]}
            justifyContent={"center"}
            width={["80vw", "90vw"]}
            height={["40vh", "16vh"]}
            alignItems={"center"}
            marginLeft={["4vw", "4vw"]}>
            <select
              className="dropdown"
              style={{ fontSize: "1.2rem", width: "80vw" }}>
              <option value="option1">Looking For</option>
              <option value="option2">Medical in India</option>
              <option value="option3">Medical in Abroad</option>
            </select>

            <select
              className="dropdown"
              style={{ fontSize: "1.2rem", width: "80vw" }}>
              <option value="option1">Course Type</option>
              <option value="option2">Under Graduate</option>
              <option value="option3">Post Graduate</option>
            </select>

            <select
              className="dropdown1"
              style={{ fontSize: "1.2rem", width: "80vw" }}>
              <option value="option1">Course Name</option>
              <option value="MBBS">MBBS</option>
              <option value="MD">MD</option>
              <option value="MS">MS</option>
              <option value="DM">DM</option>
              <option value="MCH">MCH</option>
              <option value="DIP">DIP</option>
            </select>
          </Stack>
        </Box>

        <Button
          onClick={handlesubmit}
          // padding={"7px 18px"}
          marginTop={"6vh"}
          fontSize={["1rem", "1.5rem", "1rem", "1.5rem", "1.5rem"]}
          background={"#01B4EC"}
          color={"black"}
          fontFamily={"Jost"}
          fontWeight={"normal"}
          width={["30vw", "14vw"]}>
          Submit
        </Button>
      </Flex>
      <Flex
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        direction={["column", "column", "column", "row", "row"]}
        marginTop={"7vh"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={6}>
        <Box
          textAlign={"center"}
          border={"3px solid #01B4EC"}
          width={{ base: "80%", md: "50%", lg: "30%" }}
          borderRadius={"16px"}
          py={10}>
          <Text color={"#01B4EC"} fontSize={"3rem"} fontWeight={"600"}>
            <Runningnumber
              initialnumber={200}
              targetNumber={859}
              interval={10}
            />
          </Text>

          <Text fontWeight={"300"}>Global University Tie Ups</Text>
        </Box>

        <Box
          textAlign={"center"}
          border={"3px solid #01B4EC"}
          width={{ base: "80%", md: "50%", lg: "30%" }}
          borderRadius={"16px"}
          py={10}>
          <Text color={"#01B4EC"} fontSize={"3rem"} fontWeight={"600"}>
            <Runningnumber
              initialnumber={2000}
              targetNumber={2940}
              interval={10}
            />
          </Text>

          <Text fontWeight={"300"}>Student placed</Text>
        </Box>

        <Box
          textAlign={"center"}
          border={"3px solid #01B4EC"}
          width={{ base: "80%", md: "50%", lg: "30%" }}
          borderRadius={"16px"}
          py={10}>
          <Text color={"#01B4EC"} fontSize={"3rem"} fontWeight={"600"}>
            <Runningnumber initialnumber={0} targetNumber={15} interval={170} />
          </Text>

          <Text fontWeight={"300"}>Year of Industry Presence</Text>
        </Box>
      </Flex>

      {topRecommendedCollege.length === 0 ? (
        <Box
          width={"100%"}
          height={"50vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Text>Loading Colleges....</Text>
          <CircularProgress isIndeterminate color="skyblue" />
        </Box>
      ) : (
        <Box
          className="container16"
          mt={20}
          py={12}
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#CFEBFD",
            alignItems: "center",
          }}>
          <h2 style={{ fontSize: "1.5rem", zIndex: "1", fontWeight: "bold" }}>
            Top Recommended
            <span style={{ color: "#01B4EC" }}>
              {" "}
              Medical Colleges In India{" "}
            </span>{" "}
          </h2>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
              xl: "repeat(4, 1fr)",
            }}
            gap={6}
            width="80vw">
            {topRecommendedCollege?.map((items) => (
              <GridItem>
                <Box
                  borderRadius={"xl"}
                  key={items._id}
                  width={"100%"}
                  // height={"55vh"}
                  background={"white"}
                  mt={"30"}
                  boxShadow=" 8px 4px 8px 4px rgba(0, 0, 0, 0.1)"
                  display="flex"
                  flexDirection="column"
                  alignItems={"center"}
                  backgroundColor={"white"}
                  zIndex={"1"}>
                  <Image
                    borderTopRadius={"xl"}
                    width={"100%"}
                    height={250}
                    src={items.image1}
                  />

                  <Box
                    width={"90%"}
                    margin={"auto"}
                    height={200}
                    display={"flex"}
                    flexDirection={"column"}
                    position={"relative"}
                    gap={4}
                    py={6}>
                    <Text margin={"auto"} width={"88%"} fontWeight={600}>
                      {items.collegename}
                    </Text>

                    <Flex justifyContent={"space-between"} px={2}>
                      <Box
                      // position={"absolute"}
                      // right={"4px"}
                      // top={["14vw", "4vw"]}
                      >
                        <Flex>
                          <FaRegClock
                            color="#01B4EC"
                            fontSize={"1rem"}
                            style={{ marginRight: "4px" }}
                          />
                          <Text fontSize={"1rem"} color={"rgba(0,0,0,0.7)"}>
                            {items.collegetype}
                          </Text>
                        </Flex>
                      </Box>

                      <Box
                      // position={"absolute"}
                      // left={"4px"}
                      // top={["20vw", "40vw"]}
                      >
                        <Flex>
                          <FaMapMarkerAlt
                            color="#01B4EC"
                            fontSize={"1rem"}
                            style={{ marginRight: "4px" }}
                            size={"18px"}
                          />
                          <Text fontSize={"1rem"} color={"rgba(0,0,0,0.7)"}>
                            {items.state}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>

                    <Flex justifyContent={"space-between"} px={2}>
                      <Box
                        border={"3px solid #01B4EC"}
                        borderRadius={"10px"}
                        cursor={"pointer"}
                        _hover={{ backgroundColor: "#01B4EC" }}>
                        <Link to={`/universities/state/${items._id}`}>
                          <Text fontSize={"1rem"} padding={"5px"}>
                            View Details
                          </Text>
                        </Link>
                      </Box>

                      <Box
                        border={"3px solid #01B4EC"}
                        borderRadius={"10px"}
                        cursor={"pointer"}
                        _hover={{ backgroundColor: "white" }}
                        backgroundColor={"#01B4EC"}>
                        <a
                          href={items.website}
                          target="_blank"
                          rel="noopener noreferrer">
                          <Text fontSize={"1rem"} padding={"5px"}>
                            Apply Now
                          </Text>
                        </a>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}

      {/* <Box
        style={{
          // width: "100vw",
          // height: "90vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          // marginTop: "6vh",
        }}
        // border={"5px solid red"}
      >
        <Box fontSize={["1rem", "3rem"]} py={4}>
          <h2 style={{ marginTop: "1vh" }}>
            About Us
            <br />
            <span
              style={{ color: "#01B4EC", fontWeight: "200", marginTop: "18vh" }}
            >
              {" "}
              About Admission Possibility{" "}
            </span>
          </h2>
        </Box>

        <Stack
          // height={{ base: "160vh", md: "90vh", lg: "50vh", xl: "160vh" }}
          width={"94%"}
          margin={"auto"}
          flexDirection={{ base: "column", md: "column", lg: "row" }}
          gap={6}
        >
          <Box
            width={{ base: "90%", sm: "90%", md: "80%", xl: "50%" }}
            margin="auto"
            // height={{ base: "0vh", md: "auto" }}
          >
            <Image
              src={img9}
              w={{ base: "90%", md: "60vw" }}
              margin={"auto"}
              h="auto"
            />
          </Box>

          <Box
            width={{ base: "90%", sm: "90%", md: "80%", xl: "50%" }}
            margin={"auto"}
          >
            <Box
              style={{
                fontSize: "1rem",
                color: "rgba(0,0,0,0.6)",
              }}
              textAlign={"start"}
            >
              Welcome to Admission Possibility. We are a dedicated team of
              educators and technology enthusiasts, on a mission to empower
              students like you in finding the perfect college match. With a
              wealth of experience in education and technology, we've crafted an
              intuitive, innovative, and efficient platform to streamline your
              college search journey. Join us, and let's embark on the path to
              academic and personal growth!
              <br />
              Our commitment goes beyond listings; it's about guiding you
              towards your ideal educational destination and future success.
              Explore the world of higher education possibilities that await you
              at Admission Possibility."
              <Box marginTop={"3vh"}>
                <Flex>
                  <Icon as={StarIcon} w={25} h={25} color="#01B4EC" />
                  <Text marginLeft={"10px"}>Comprehensive Listings</Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex>
                  <Icon as={StarIcon} w={25} h={25} color="#01B4EC" />
                  <Text marginLeft={"10px"}>User-Friendly Interface</Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex>
                  <Icon as={StarIcon} w={25} h={25} color="#01B4EC" />
                  <Text marginLeft={"10px"}>Accurate Information</Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex>
                  <Icon as={StarIcon} w={25} h={25} color="#01B4EC" />
                  <Text marginLeft={"10px"}>Personalized Recommendations</Text>
                </Flex>
              </Box>
              <Box marginTop={"3vh"}>
                <Flex>
                  <Icon as={StarIcon} w={25} h={25} color="#01B4EC" />
                  <Text marginLeft={"10px"}>Feedback Integration</Text>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box> */}

      <Box
        className="container4"
        py={10}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <h2>
          <Text fontSize={{ base: "1.4rem", lg: "2rem" }}>OUR SERVICES</Text>
          <Text
            fontSize={{ base: "1.4rem", lg: "2rem" }}
            color={"#01B4EC"}
            fontWeight={"40"}
            marginTop={"1vh"}>
            Admission Possibility Services
          </Text>
        </h2>

        <Grid
          templateColumns={{
            base: "1fr",
            sm: "1fr 1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(3, 1fr)",
          }}
          gap={10}
          width={{ base: "95vw", md: "90vw", lg: "80vw" }}
          margin="auto"
          // padding={{ base: "4vw", md: "6vw", lg: "8vw" }}
          py={10}
          px={10}>
          {[
            {
              src: img10,
              title: "College Predictors",
              description:
                "MBBS Buddy is India’s first data analytics based MBBS College predictor",
            },
            {
              src: img11,
              title: "Expert Counsellors",
              description:
                "Our team comprises of best experts with more than a decade’s experience",
            },
            {
              src: img12,
              title: "College Level Analysis",
              description:
                "Our college visits give us real on ground student & patient feedback",
            },
            {
              src: img13,
              title: "Transparent Process",
              description:
                "Transparency through process driven systematic approach",
            },
            {
              src: img14,
              title: "Personalised Guidance",
              description:
                "Personalised services with right fit to the right student",
            },
            {
              src: img15,
              title: "Alerts & Notification",
              description:
                "Date, Time & Other NEET Updates & Notification on SMS & Email",
            },
          ].map(({ src, title, description }, index) => (
            <GridItem
              key={index}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              borderRadius="15px"
              boxShadow="4px 4px 4px 4px rgba(0, 0, 0, 0.1)"
              fontSize="0.9rem"
              bg="white"
              transition="all 0.3s ease"
              py={6}
              _hover={{ boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)" }}>
              <Image src={src} height="8vh" mb="4" />
              <Text fontWeight="bold" fontSize="1rem" mb="2">
                {title}
              </Text>
              <Text color="gray.600" width={"70%"}>
                {description}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </Box>

      <Box
        className="universities"
        display={"flex"}
        justifyContent={"center"}
        backgroundColor={"#CFEBFD"}
        alignItems={"center"}
        flexDirection={"column"}
        position={"relative"}
        py={4}>
         {" "}
        <Text fontSize={"2.575rem"} color={"#01B4EC"} mb={6}>
          Blogs
        </Text>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          textAlign={"left"}
          gap={6}
          width={{ base: "90%", lg: "80%" }}
          marginBottom={6}>
          {formData &&
            formData?.map((ele) => (
              <Link to={`blogs/singleBlogPage/${ele._id}`} key={ele._id}>
                <GridItem boxShadow={"lg"}>
                  <Box width={"100%"} height={"100%"}>
                    <Image
                      borderTopRadius={"lg"}
                      src={ele.image}
                      width={"100vw"}
                      height={"30vh"}
                    />
                  </Box>

                  <Flex
                    flexDirection={"column"}
                    gap={2}
                    borderBottomRadius={"lg"}
                    py={4}
                    px={3}
                    width={"100%"}
                    backgroundColor={"white"}>
                    <Text fontSize={"0.7rem"} color={"#3939BC"}>
                      {ele.date}
                    </Text>
                    <Text
                      color={"#00008B"}
                      fontWeight={"bold"}
                      width={"90%"}
                      fontSize={"1rem"}>
                      {ele.title.split("<br></br>")[0].substring(0, 20) + "..."}
                    </Text>
                    <Text
                      fontWeight={800}
                      fontSize={"0.8rem"}
                      width={"90%"}
                      color={"#333333"}
                      dangerouslySetInnerHTML={{
                        __html:
                          ele.description
                            .split("<br></br>")[0]
                            .substring(0, 60) + "...",
                      }}></Text>{" "}
                  </Flex>
                </GridItem>
              </Link>
            ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
