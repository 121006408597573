export const state = [
  "Karnataka",
  "Maharashtra",
  "Punjab",
  "Haryana",
  "Tamil Nadu",
  "Andhra Pradesh",
  "Gujarat",
  "Kerala",
  "Odisha",
  "Telangana",
  "Jharkhand",
  "Rajasthan",
  "Tripura",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Uttarakhand",
  "Delhi",
  "Himachal Pradesh",
  "Goa",
  "West Bengal",
  "Uttar Pradesh",
  "Andaman and Nicobar Islands",
  "Jammu & Kashmir",
  "Madhya Pradesh",
  "Arunachal Pradesh",
  "Dadra and Nagar Haveli",
];


export const ownership = ["Public", "Private"];



