import React from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
  Img,
} from "@chakra-ui/react";
import img1 from "./kaza1.webp"; // Update if needed
import img2 from "./kaza2.png"; // Update if needed
import Internation from "../../pages/Internation";
import bgImage from "./kaza_banner.png"; // Update if needed

const Kazakhstan = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`} // Update background image
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
          "MBBS In Kazakhstan"
        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={4}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In Kazakhstan
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              Kazakhstan is the largest landlocked country in the world, located in Central Asia. It is bordered by Russia to the north, China to the east, Kyrgyzstan, Uzbekistan, and Turkmenistan to the south, and the Caspian Sea to the west. Its diverse landscape includes steppes, deserts, and mountains, with the capital city being Nur-Sultan (formerly Astana).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              Approximately 19.5 million (2024).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              2,724,900 km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Nur-Sultan (formerly Astana)
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              Kazakhstan has a continental climate with harsh winters and hot summers. The western and northern regions experience extreme temperatures, while the central and southern areas have milder winters and hot summers. The country has a generally dry climate with low precipitation.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              The majority religion in Kazakhstan is Islam, practiced by around 70% of the population. Christianity, predominantly Russian Orthodox, is practiced by about 26% of the population. Other religions and non-religious individuals make up the remaining percentage.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              99.8% (Male - 99.9% and Female - 99.7%).
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4}>
              <Img src={img1} width="100%" borderRadius="md" boxShadow="md" />
              <Img src={img2} width="100%" borderRadius="md" boxShadow="md" />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why Kazakhstan?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Affordable Tuition Fees</li>
            <li>Programs Offered in English</li>
            <li>Five to Six Years MBBS Program</li>
            <li>Accredited by WHO and Various Medical Councils</li>
            <li>Low Cost of Living</li>
            <li>Rich Cultural Heritage</li>
            <li>Safe and Peaceful Environment</li>
            <li>No Entrance Exam for International Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          Kazakhstan MBBS Colleges
        </Heading>
        <Internation state="Kazakhstan" />
      </Box>
    </Box>
  );
};

export default Kazakhstan;
