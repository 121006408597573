import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import axios from "axios";
import { ownership, state } from "../Statecollege/data";
export const Filter = ({ handleFilterChange, handleCityChange, City }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [activeCity, setActiveCity] = useState(null);

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked && activeFilter !== item) {
      setActiveFilter(item);
      handleFilterChange(item);
    } else if (!e.target.checked && activeFilter === item) {
      setActiveFilter(null);
      handleFilterChange(null);
    }
  };

  const handleCitySelection = (e, city) => {
    if (e.target.checked && activeCity !== city) {
      setActiveCity(city);
      handleCityChange(city);
    } else if (!e.target.checked && activeCity === city) {
      setActiveCity(null);
      handleCityChange(null);
    }
  };

  return (
    <Flex
      w="25vw"
      boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      p="5"
      flexDirection={"column"}
      gap={6}
      overflowY="scroll"
      overflowX={"hidden"}>
      {/* State Filter */}
      <Flex flexDirection={"column"}>
        <HStack mb={2}>
          <Heading fontSize={"1.3rem"}>Filter By</Heading>
          <Spacer />
          <Text color={"red"} fontWeight={"500"}>
            STATE
          </Text>
        </HStack>
        <hr />
        <VStack mt={3} align={"flex-start"} spacing={2} w="100%">
          {state?.map((item, index) => (
            <Checkbox
              key={index}
              onChange={(e) => handleCheckboxChange(e, item)}
              isChecked={activeFilter === item}>
              {item}
            </Checkbox>
          ))}
        </VStack>
      </Flex>

      {/* City Filter */}
      {City && (
        <Flex flexDirection={"column"}>
          <HStack mb={2}>
            <Heading fontSize={"1.3rem"}>Filter By</Heading>
            <Spacer />
            <Text color={"red"} fontWeight={"500"}>
              CITY
            </Text>
          </HStack>
          <hr />
          <VStack mt={3} align={"flex-start"} spacing={2} w="100%">
            {City.map((item, index) => (
              <Checkbox
                key={index}
                onChange={(e) => handleCitySelection(e, item)}
                isChecked={activeCity === item}>
                {item}
              </Checkbox>
            ))}
          </VStack>
        </Flex>
      )}
    </Flex>
  );
};
