import {
  Box,
  Button,
  CircularProgress,
  Grid,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { MdPlace } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const Internation = ({ state }) => {
  const [universities, setUniversities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `https://api.admissionpossibility.com/api/universities/${state}`
      )
      .then((response) => {
        setUniversities(response.data.slice(0, 4));
        // console.log("saas",response.data)
      })
      .catch((error) => {
        console.error("Error fetching universities:", error);
      });
  }, [state]);

  const handleClick = (universityName) => {
    console.log("asas", universityName);
    navigate(`/universities/state/${universityName}`);
  };

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      width={{ base: "100%", lg: "64%" }}
      margin="auto"
      gap={10}
      py={6}
    >
      {universities.length === 0 ? (
        <Box width="100%" textAlign="center" mt={6}>
          <Text>Loading Colleges....</Text>
          <CircularProgress isIndeterminate color="blue" />
        </Box>
      ) : (
        universities.map((university) => (
          <Box
            key={university.collegename}
            borderWidth="1px"
            borderColor="#01B4EC"
            borderRadius="20px"
            overflow="hidden"
            p={4}
            bg="white"
            cursor="pointer"
            boxShadow="lg"
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              boxShadow: "2xl",
            }}
          >
            <HStack spacing={4} alignItems="flex-start">
              <Image
                src={university.image2}
                alt={university.collegename}
                boxSize="100px"
                objectFit="cover"
                borderRadius="full"
              />
              <VStack align="flex-start" spacing={2} flex="1">
                <Text
                  color={"#01B4EC"}
                  fontSize="1.2rem"
                  fontWeight="bold"
                  noOfLines={1}
                >
                  {university.collegename}
                </Text>
                <HStack fontSize="1rem" color="gray.600" spacing={1}>
                  <Icon as={MdPlace} />
                  <Text noOfLines={1}>
                    {university.address}, {university.state}
                  </Text>
                </HStack>
                {university.courses
                  .filter(
                    (course) => course.course.replace(/\s/g, "") === "MBBS"
                  )
                  .map((ele) => (
                    <Box
                      key={ele.course}
                      bg="gray.100"
                      width="100%"
                      p={2}
                      borderRadius="md"
                      textAlign="left"
                    >
                      <Text fontSize="1rem" textAlign={"center "}>
                        <strong>Courses Offered:</strong> {ele.course}
                      </Text>
                    </Box>
                  ))}
              </VStack>
            </HStack>
            <Button
              mt={4}
              // colorScheme="blue"
              borderRadius="10px"
              width="full"
              onClick={() => handleClick(university._id)}
              border={"2px solid #023B7D"}
              cursor={"pointer"}
              fontSize={{ base: "0.6rem", lg: "1rem" }}
              height={"4vh"}
              _hover={{ backgroundColor: "#01B4EC", color: "white" }}
            >
              Details
            </Button>
          </Box>
        ))
      )}
    </Grid>
  );
};

export default Internation;
