import React, { useState } from "react";
import { Box, background, position, button, useToast } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import {
  HStack,
  Flex,
  Icon,
  Divider,
  Button,
  Heading,
  VStack,
  FormControl,
  Input,
  Textarea,
  Image,
} from "@chakra-ui/react";
import img1 from "./call-us.jpg";
import "./contact.css";
import axios from "axios";

const Contact = () => {
  const toast = useToast();
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    mobile: "",
    description: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({ ...prev, [name]: value }));
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault(); // Corrected spelling of preventDefault
    console.log("contactData:", contactData);
    // Add logic for form submission here
    try {
      const responce = await axios.post(
        // `http://localhost:5000/api/contact`,
        `https://api.admissionpossibility.com/api/contact`,
        contactData
      );
      if (responce.status === 200) {
        toast({
          title: `Thank you for contacting us!`,
          status: "success",
          isClosable: true,
        });
        setContactData({
          name: "",
          email: "",
          mobile: "",
          description: "",
        });
      }

      console.log("responce:", responce);
    } catch (error) {
      console.log("error:", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleContactFormSubmit}>
        <Flex
          width={{ base: "100%", lg: "80%" }}
          margin={"auto"}
          mt={"12vh"}
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            xl: "row",
          }}
          py={10}>
          <Image
            src={img1}
            width={{ base: "80%", sm: "80%", md: "80%", xl: "40%" }}
            // h={"70vh"}
            margin={"auto"}
          />

          <VStack
            width={{ base: "80vw", sm: "80vw", md: "45vw", xl: "40%" }}
            margin={"auto"}>
            <Text
              style={{ color: "#000000" }}
              fontSize={"2rem"}
              fontWeight={800}>
              CONTACT US{" "}
            </Text>

            <Text
              fontSize={{ base: "1.6rem" }}
              style={{ color: "rgb(1,180,236)" }}
              textAlign={"center"}>
              Request a Callback
            </Text>

            <FormControl isRequired marginTop={"2vh"}>
              <Input
                onChange={handleInput}
                name="name"
                value={contactData.name}
                placeholder="Full name"
                width={{ base: "100%", lg: "90%" }}
                margin={"auto"}
              />
            </FormControl>

            <FormControl isRequired marginTop={"2vh"}>
              <Input
                onChange={handleInput}
                name="email"
                value={contactData.email}
                placeholder="Email"
                width={{ base: "100%", lg: "90%" }}
                margin={"auto"}
              />
            </FormControl>

            <FormControl isRequired marginTop={"2vh"}>
              <Input
                onChange={handleInput}
                name="mobile"
                value={contactData.mobile}
                placeholder="Mobile"
                width={{ base: "100%", lg: "90%" }}
                margin={"auto"}
              />
            </FormControl>

            <FormControl isRequired marginTop={"2vh"}>
              <Textarea
                onChange={handleInput}
                name="description"
                value={contactData.description}
                placeholder="Enter description"
                width={{ base: "100%", lg: "90%" }}
                margin={"auto"}></Textarea>
            </FormControl>

            <Button
              type="submit"
              marginTop={"2vh"}
              color={"white"}
              fontWeight={"400"}
              backgroundColor={"rgb(1,180,236)"}
              padding={"5px 40px"}
              _hover={{
                color: "black",
                border: "2px solid rgb(1,180,236)",
                backgroundColor: "white",
              }}
              marginBottom={"5vh"}>
              Submit
            </Button>
          </VStack>
        </Flex>
      </form>
    </div>
  );
};

export default Contact;
