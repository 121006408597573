import React from "react";
import img9 from "./abouts.avif";

import {
  Box,
  background,
  position,
  button,
  Image,
  Stack,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";

import {
  HStack,
  Flex,
  Icon,
  Divider,
  Button,
  Heading,
  VStack,
} from "@chakra-ui/react";

const About = () => {
  return (
    <Box>
      <Box
        className="container4"
        width={"100%"}
        // height={{ base: "180vh", md: "100vh", lg: "50vh", xl: "100vh" }}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        backgroundColor="#CFEBFD"
        py={5}
        marginTop={"16vh"}
        gap={12}
      >
        <h2
          style={{
            fontSize: "2rem",
            marginTop: "10px",
            color: "#023B7D",
            fontWeight: "bold",
          }}
        >
          About Admission Possibility
        </h2>

        <Flex
          // height={{ base: "160vh", md: "90vh", lg: "50vh", xl: "160vh" }}
          width={{base:"100%",lg:"90%"}}
          margin={"auto"}
          flexDirection={{ base: "column", md: "column", lg: "row" }}
          // gap={6}
          justifyContent={"center"}
        >
          <Box
            width={{ base: "90%", sm: "90%", md: "80%", xl: "45%" }}
            margin="auto"
            // height={{ base: "0vh", md: "80vh" }}
          >
            <Image
              src={img9}
              width={"100%"}
              // height={400}
              borderRadius={"xl"}
              margin={"auto"}
            />
          </Box>

          <Box
            width={{ base: "90%", sm: "90%", md: "80%", xl: "50%" }}
            margin="auto"
            padding={{ base: "4", md: "3" }}
            // backgroundColor="white"
            // borderRadius="md"
            // boxShadow="md"
          >
            <Box
              fontSize={{ base: "sm", md: "1rem" }}
              color="gray.700"
              textAlign="start"
            >
              <Text mb="4">
                Welcome to Admission Possibility. We are a dedicated team of
                educators and technology enthusiasts, on a mission to empower
                students like you in finding the perfect college match. With a
                wealth of experience in education and technology, we've crafted
                an intuitive, innovative, and efficient platform to streamline
                your college search journey. Join us, and let's embark on the
                path to academic and personal growth!
              </Text>
              <Text mb="4">
                Our commitment goes beyond listings; it's about guiding you
                towards your ideal educational destination and future success.
                Explore the world of higher education possibilities that await
                you at Admission Possibility."
              </Text>

              <Box mt="6">
                {[
    "Comprehensive Listings: Explore a wide range of colleges and universities with detailed profiles to help you make informed decisions based on your preferences.",
    "User-Friendly Interface: Enjoy an intuitive platform with easy navigation and streamlined search functions for a hassle-free experience.",
    "Accurate Information: Rely on up-to-date and precise details about academic programs, admissions, and campus facilities.",
    "Personalized Recommendations: Receive tailored college suggestions based on your interests, background, and career goals.",
    "Feedback Integration: Share your feedback to help us continually improve and enhance the platform for a better user experience."
  ].map((item, index) => (
                  <Box key={index} mb="4">
                    <Flex align="center">
                      <Icon as={StarIcon} w={5} h={5} color="#01B4EC" />
                      <Text
                        ml="3"
                        fontSize={{ base: "sm", md: "sm" }}
                        fontWeight="medium"
                      >
                        {item}
                      </Text>
                    </Flex>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default About;
