import React from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
  Img,
} from "@chakra-ui/react";
import img1 from "./nepal1.jpg"; // Update if needed
import img2 from "./nepal2.jpg"; // Update if needed
import Internation from "../../pages/Internation";
import bgImage from "./nepal_banner.jpg"; // Update if needed

const Nepal = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`} // Update background image
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
          "MBBS In Nepal"
        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={4}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In Nepal
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              Nepal is a landlocked country located in South Asia, nestled in the Himalayas. It is bordered by China to the north and India to the south, east, and west. The country is renowned for its diverse topography, including the world’s highest peaks like Mount Everest. The capital city is Kathmandu, situated in the Kathmandu Valley.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              Approximately 31.8 million (2024).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              147,516 km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Kathmandu
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              Nepal's climate varies from the tropical in the lowlands to alpine in the high mountains. The Terai region experiences a subtropical climate with hot summers and mild winters, while the hill regions have a temperate climate with cooler temperatures. The mountainous areas experience severe cold with heavy snowfall.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              Hinduism is the predominant religion in Nepal, practiced by around 81% of the population. Buddhism follows with about 9%, and other religions, including Islam and Christianity, make up the remainder.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              71.7% (Male - 79.6% and Female - 64.5%).
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4}>
              <Img src={img1} height={{base:"30vh",lg:"30vh"}} width="100%" borderRadius="md" boxShadow="md" />
              <Img src={img2} height={{base:"30vh",lg:"30vh"}} width="100%" borderRadius="md" boxShadow="md" />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why Nepal?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Affordable Tuition Fees</li>
            <li>Programs Offered in English</li>
            <li>Five to Six Years MBBS Program</li>
            <li>Accredited by WHO and Various Medical Councils</li>
            <li>Low Cost of Living</li>
            <li>Rich Cultural and Natural Heritage</li>
            <li>Safe and Welcoming Environment</li>
            <li>No Entrance Exam for International Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          Nepal MBBS Colleges
        </Heading>
        <Internation state="Nepal" />
      </Box>
    </Box>
  );
};

export default Nepal;
