import React from "react";
import {
  Box,
  Text,
  HStack,
  Img,
  Heading,
  VStack,
} from "@chakra-ui/react";
import img1 from "./georgia1.jpg";
import img2 from "./georgia2.jpeg";
import Internation from "../../pages/Internation";
import bgImage from "./georgia_banner.jpg"
const Georgia = () => {
  return (
    <Box>
      <Box
        position="relative"
        width="100%"
        height="70vh"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundImage={`url(${bgImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundColor="rgba(0, 0, 0, 0.75)"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.5)" // Overlay effect
        />
        <Text
          fontSize="2.8rem"
          color="white"
          zIndex="1"
          textAlign="center"
          position="relative"
        >
          "MBBS In Georgia"

        </Text>
      </Box>

      <Box
        width={{ base: "98%", lg: "80%" }}
        px={2}
        margin="auto"
        fontFamily="Roboto"
        py={6}
      >
        <HStack width={"100%"} spacing={8} align="flex-start">
          <Box
            fontSize="1rem"
            width={{ base: "100%", lg: "60%" }}
            textAlign="left"
          >
            <Heading marginBottom="4vh" fontFamily="Poppins">
              MBBS In Georgia
            </Heading>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Geography:
              </Text>{" "}
              Georgia is a country in the Caucasus region of Eurasia. Located at
              the crossroads of Western Asia and Eastern Europe, it is bounded
              to the west by the Black Sea, to the north by Russia, to the south
              by Turkey and Armenia, and to the southeast by Azerbaijan. The
              capital and largest city is Tbilisi. Georgia covers a territory of
              69,700 square kilometers, and its population as of 2024 is around
              3.7 million. Georgia is a unitary, semi-presidential republic, with
              the government elected through a representative democracy.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Population:
              </Text>{" "}
              Approximately 3.7 million (2024).
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Area:
              </Text>{" "}
              69,700 km²
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Capital:
              </Text>{" "}
              Tbilisi
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Climate:
              </Text>{" "}
              The climate of Georgia remains diverse, with two main climatic zones:
              the eastern and western parts of the country. The Greater Caucasus
              Mountain Range continues to play a significant role in moderating
              Georgia's climate, protecting it from colder northern air masses and
              the Lesser Caucasus Mountains shield it from dry and hot southern air
              masses.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Religions:
              </Text>{" "}
              The predominant religion in Georgia remains Eastern Orthodox Christianity,
              practiced by around 83% of the population. Islam follows with about 10%,
              the Armenian Apostolic Church at 2.9%, and other religions at approximately
              4%.
            </Text>
            <Text mb={4}>
              <Text as="span" fontWeight="bold" color="#1C375B">
                Literacy rate:
              </Text>{" "}
              99.8% (Male - 99.8% and Female - 99.7%).
            </Text>
          </Box>
          <Box
            width={{ base: "100%", lg: "40%" }}
            display={{ base: "none", lg: "block" }}
          >
            <VStack spacing={4} >
              <Img src={img1} height={{base:"50vh",lg:"30vh"}} width="100%" borderRadius="md" boxShadow="md" />
              <Img src={img2} height={{base:"50vh",lg:"30vh"}} width="100%" borderRadius="md" boxShadow="md" />
            </VStack>
          </Box>
        </HStack>

        <Box mt={8}>
          <Text textAlign="start" fontWeight="bold" color="#1C375B" mb={4}>
            Why Georgia?
          </Text>
          <ul style={{ marginLeft: "3vw", textAlign: "start" }}>
            <li>Low Tuition Fee</li>
            <li>Full English Medium</li>
            <li>Five years MBBS Programme</li>
            <li>WHO, PM & DC Approved</li>
            <li>Master the USMLE based programme</li>
            <li>
              Study from the latest USA medical Textbooks, Manuals, and other
              aids
            </li>
            <li>
              Language is not a barrier since the medium of education is
              English.
            </li>
            <li>No Entrance Exam for Indian Students</li>
          </ul>
        </Box>
      </Box>
      <Box
        width={{ base: "90%", lg: "80%" }}
        margin={"auto"}
        fontFamily="Roboto"
        py={6}
      >
        <Heading
          fontSize={{ base: "1.4rem", lg: "1.5rem" }}
          color="#1C375B"
          mb={4}
        >
          Georgia MBBS Colleges
        </Heading>
        <Internation state="Georgia" />
      </Box>
    </Box>
  );
};

export default Georgia;
