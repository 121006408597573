import { Grid, Box, Text, HStack, CircularProgress } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import UniversityCard from "../components/Courseuniversity/UniversityCard";
import { Filter } from "../components/Filter/Filter";
import { useParams } from "react-router-dom";

function UniversitiesListiningPage() {
  const { state } = useParams();

  const [universities, setUniversities] = useState([]);
  const [citys, setCity] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);
  const [filteredUniversity, setFilteredUniversity] = useState("");
  const [cityWish, setCityWish] = useState("");

  const handleFilterChange = (selectedFilter) => {
    setFilteredUniversity(selectedFilter);
  };

  const handleCityChange = (selectedCity) => {
    console.log("city", selectedCity);
    setCityWish(selectedCity);
  };
  console.log("state with", filteredUniversity, cityWish);
  useEffect(() => {
    let apiUrl;

    if (filteredUniversity && cityWish) {
      // Prioritize this condition when both are present
      // apiUrl = `http://localhost:5000/api/universities/${filteredUniversity}/${cityWish}`;
      apiUrl = `https://api.admissionpossibility.com/api/universities/${filteredUniversity}/${cityWish}`;
      // apiUrl = `http://localhost:5000/api/universities/${filteredUniversity}/${cityWish}`;
    } else if (filteredUniversity) {
      apiUrl = `https://api.admissionpossibility.com/api/universities/${filteredUniversity}`;
      // apiUrl = `http://localhost:5000/api/universities/${filteredUniversity}`;
    } else if (state) {
      apiUrl = `https://api.admissionpossibility.com/api/universities/${state}`;
      // apiUrl = `http://localhost:5000/api/universities/${state}`;
    } else {
      console.error(
        "Neither state nor filteredUniversity are available. Please provide a valid URL."
      );
      return;
    }

    axios
      .get(apiUrl)
      .then((response) => {
        setUniversities(response.data);
        if (filteredUniversity && !cityWish) {
          const cities = [
            ...new Set(response.data.map((university) => university.city)),
          ];
          setCity(cities);
        }

        console.log("Fetched Data:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching universities:", error);
      });
  }, [filteredUniversity, state, cityWish]);

  useEffect(() => {
    switch (state.toLowerCase()) {
      case "karnataka":
        setBannerImage();
        break;
      case "uttarpradesh":
        setBannerImage();
        break;
      case "delhi":
        setBannerImage();
        break;
      case "bihar":
        setBannerImage();
        break;
      default:
        setBannerImage(null);
        break;
    }
  }, [state]);

  return (
    <div>
      <HStack>
        <Box mt={36}>
          {/* {bannerImage && (
            <img
              style={{ width: "100vw", height: "35vh" }}
              src={bannerImage}
              alt={`Banner for ${state}`}
            />
          )} */}

          {/* <Text
            fontSize="1.7rem"
            fontWeight="bold"
            background={"rgb(1,180,236)"}
            mb={4}
            textAlign={"center"}
            color={"white"}
          >
            Universities in {state}
          </Text> */}

          <HStack alignItems={"start"}>
            <Box display={["none", "none", "block", "block"]}>
              <Filter
                handleFilterChange={handleFilterChange}
                handleCityChange={handleCityChange}
                City={citys}
              />
            </Box>

            <Grid templateColumns="repeat(1,1fr)" gap={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "6vh",
                }}>
                {universities.length === 0 ? (
                  <Box width={"50vw"} margin={"auto"} mt={6}>
                    <Text>Loading Colleges....</Text>
                    <CircularProgress isIndeterminate color="blue" />
                  </Box>
                ) : (
                  universities.map((university) => (
                    <UniversityCard
                      key={university._id}
                      university={university}
                      style={{ marginBottom: "6vh" }}
                    />
                  ))
                )}
              </div>
            </Grid>
          </HStack>
        </Box>
      </HStack>
    </div>
  );
}

export default UniversitiesListiningPage;
